<style lang="scss" scoped>
    .text {color:#999;font-size:35px;text-align:center;margin-top:100px;}
</style>

<template>
    <div class="wrap">
        <div class="text">内容建设中，敬请期待...</div>
    </div>
</template>

<script>
export default {
    data () {
        return {
        }
    },

    created() {

    },
    components: {},

    computed:{},

    methods: {}
}

</script>